import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import starAnimation from "./anim/star.json";
import groguAnimation from "./anim/grogu.json";
import "./App.css";

const App = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: starAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: groguAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        width={windowSize.width}
        height={windowSize.height}
        eventListeners={[
          {
            eventName: "complete",
            callback: () => {
              setAnimationCompleted(true);
            },
          },
        ]}
      />
      <div
        className={
          animationCompleted ? "fade-in-element fade-in" : "fade-in-element"
        }
      >
        <div className="text">
          <h3>Coming Soon</h3>
          <h5>
            Exciting things are in the works! Our team of frontend developers is
            crafting something amazing. Stay tuned for a stunning web experience
            that's just around the corner.
          </h5>
        </div>
        <div>
          <Lottie options={defaultOptions2} width={250} height={250} />
        </div>
      </div>
    </div>
  );
};

export default App;
